import React, { useCallback } from "react";
import dynamic from "next/dynamic";
import BusinessFieldsType from "types/business.types";
import useTranslation from "next-translate/useTranslation";
import { useCanRender } from "hooks/useRender";
import useWindowSize from "hooks/useWindowSize";
import { useMakeLabel } from "hooks/useTabs";

const Info = dynamic(() => import("components/About/Info.about"), {
	ssr: true,
});

const Locations = dynamic(() => import("components/About/Locations.about"), {
	ssr: true,
});

const Sticky = dynamic(() => import("components/About/Sticky.about"), {
	ssr: true,
});

type Props = { profile: BusinessFieldsType };

export default function AboutContainer({ profile }: Props) {
	const isLocationRenderable = useCanRender("location");
	const { t } = useTranslation("common");
	const description = profile.description || t("sections.about.description");
	const { width } = useWindowSize();

	const locationLabel = useMakeLabel("location", 80);

	return (
		<>
			<div className="grid grid-cols-1 md:grid-cols-12 md:gap-8 lg:gap-10 xl:gap-14 gap-y-10 mb-6">
				<div className="col-span-12 lg:col-span-6 xl:col-span-7">
					<Info businessName={profile.name} description={description} />
					{width < 1024 && <Sticky profile={profile} />}
					{isLocationRenderable && (
						<>
							<h3 className="font-medium text-lg xl:text-xl mb-4 mt-8 lg:mt-10">{locationLabel}</h3>
							<Locations locations={profile.locations} />
						</>
					)}
				</div>
				{width > 1024 && <Sticky profile={profile} />}
			</div>
		</>
	);
}
